var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_vm._m(0),_c('div',{staticClass:"login-wraper"},[_c('div',{staticClass:"login-logo"},[_c('span',{class:[
					'login-tag',
					this.loginType == 'code' ? 'login-logo--on' : ''
				],on:{"click":function($event){return _vm.selectLoginType('code')}}},[_vm._v("微信登录")]),_c('span',{staticClass:"login-line"},[_vm._v("|")]),_c('span',{class:[
					'login-tag',
					this.loginType == 'phone' ? 'login-logo--on' : ''
				],on:{"click":function($event){return _vm.selectLoginType('phone')}}},[_vm._v("账号登录")])]),_c('div',{staticStyle:{"margin-top":"10px"}}),(this.loginType == 'phone')?_c('el-form',{ref:"form",staticClass:"el-form login-form el-form--label-left",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('div',{staticClass:"account-login"},[_c('div',{staticClass:"el-form-item is-required"},[_c('div',{staticClass:"el-form-item__content",staticStyle:{"margin-left":"0px"}},[_c('el-input',{attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"el-icon-mobile-phone"})])],2)],1)]),_c('div',{staticClass:"el-form-item is-required"},[_c('div',{staticClass:"el-form-item__content",staticStyle:{"margin-left":"0px"}},[_c('el-input',{attrs:{"placeholder":"请输入密码","show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}},[_c('template',{slot:"prepend"},[_c('i',{staticClass:"el-icon-key"})])],2)],1)]),_c('div',{staticClass:"el-form-item",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"el-form-item__content",staticStyle:{"margin-left":"0px"}},[_c('el-button',{staticClass:"el-button btn-full el-button--primary",attrs:{"type":"primary","loading":_vm.form.finishLogin},on:{"click":_vm.login}},[_vm._v("登录 ")])],1)])]),_c('div',{staticClass:"forget-wraper"},[_c('span',{staticClass:"forget"},[_vm._v("忘记密码？")]),_c('p',{staticClass:"register-tip"},[_vm._v(" 还没有账号？ "),_c('span',{staticClass:"register"},[_vm._v("立即注册")])])])]):_vm._e(),(this.loginType == 'code')?_c('div',[_vm._m(1)]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"width":"329","height":"118","src":require("./img/logo1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"border":"1px solid #ececec","width":"300px","margin":"0 auto","padding-top":"30px"}},[_c('iframe',{attrs:{"src":"https://open.weixin.qq.com/connect/qrconnect?appid=wx38636c31581ccde7&scope=snsapi_login&redirect_uri=http%3a%2f%2fread.admin.xxyw100.com&state=STATE&login_type=jssdk&self_redirect=false&styletype=&sizetype=&bgcolor=&rst=&href=https://api.xxyw100.com/a.css","frameborder":"0","scrolling":"no","width":"300px","height":"400px"}})])
}]

export { render, staticRenderFns }