<template>
	<div class="login-page">
		<div><img width="329" height="118" src="./img/logo1.png" /></div>

		<div class="login-wraper">
			<div class="login-logo">
				<span
					:class="[
						'login-tag',
						this.loginType == 'code' ? 'login-logo--on' : ''
					]"
					@click="selectLoginType('code')"
					>微信登录</span
				>
				<span class="login-line">|</span>
				<span
					:class="[
						'login-tag',
						this.loginType == 'phone' ? 'login-logo--on' : ''
					]"
					@click="selectLoginType('phone')"
					>账号登录</span
				>
			</div>
			<div style="margin-top: 10px"></div>

			<el-form
				v-if="this.loginType == 'phone'"
				ref="form"
				:model="form"
				class="el-form login-form el-form--label-left"
				label-width="80px"
			>
				<div class="account-login">
					<div class="el-form-item is-required">
						<div class="el-form-item__content" style="margin-left: 0px">
							<el-input placeholder="请输入手机号" v-model="form.phone">
								<template slot="prepend">
									<i class="el-icon-mobile-phone"> </i>
									</template
								>
							</el-input>
						</div>
					</div>
					<div class="el-form-item is-required">
						<div class="el-form-item__content" style="margin-left: 0px">
							<el-input
								placeholder="请输入密码"
								show-password
								v-model="form.password"
							>
								<template slot="prepend"><i class="el-icon-key"></i></template>
							</el-input>
						</div>
					</div>
					<div class="el-form-item" style="width: 100%">
						<div class="el-form-item__content" style="margin-left: 0px">
							<el-button
								type="primary"
								class="el-button btn-full el-button--primary"
								@click="login"
								:loading="form.finishLogin"
								>登录
							</el-button>
						</div>
					</div>
				</div>
				<div class="forget-wraper">
					<span class="forget">忘记密码？</span>
					<p class="register-tip">
						还没有账号？
						<span class="register">立即注册</span>
					</p>
				</div>
			</el-form>

			<div v-if="this.loginType == 'code'">
				<div
					style="
						border: 1px solid #ececec;
						width: 300px;
						margin: 0 auto;
						padding-top: 30px;
					"
				>
					<iframe
						src="https://open.weixin.qq.com/connect/qrconnect?appid=wx38636c31581ccde7&amp;scope=snsapi_login&amp;redirect_uri=http%3a%2f%2fread.admin.xxyw100.com&amp;state=STATE&amp;login_type=jssdk&amp;self_redirect=false&amp;styletype=&amp;sizetype=&amp;bgcolor=&amp;rst=&amp;href=https://api.xxyw100.com/a.css"
						frameborder="0"
						scrolling="no"
						width="300px"
						height="400px"
					></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
	name: 'login',
	data() {
		return {
			loginType: 'code',
			form: {
				phone: '',
				password: '',
				finishLogin: false
			}
		}
	},
	methods: {
		...mapMutations(['login/setFinishLoginState']),

		login() {
			this['login/setFinishLoginState'](true)
			this.$store.dispatch('login/login', this.form).then(success => {
				console.log(success)
				if (success.ret == 0) {
					this.$cookies.set(
						'token',
						success.data.access_token,
						success.data.expires_in
					)
					this.$cookies.set('isLogin', true, success.data.expires_in)
					this['login/setFinishLoginState'](false)
					this.$router.push('/work')
				}
			})
		},
		selectLoginType(type) {
			this.loginType = type
			console.log(type)
		}
	},
	watch: {
		'$store.state.login.finishLogin'(val) {
			this.form.finishLogin = val
		}
	}
}
</script>

<style>
.el-button--primary {
	background: rgb(240, 131, 25);
	border-color: rgb(240, 131, 25);
}

body {
	background: #fff;
}

.login-page {
	padding-top: 50px;
	background-color: #fff;
	text-align: center;
}

.login-page .logo {
	position: fixed;
	top: 24px;
	left: 40px;
	width: 65px;
	height: 32px;
	cursor: pointer;
}

.login-page .login-wraper {
	height: 100%;
	min-height: 500px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.login-page .login-form {
	margin: 0 auto;
	padding: 0 70px;
	text-align: -webkit-center;
	background: #fff;
}

.login-page .experience.login-logo {
	margin-bottom: 25px;
}

.login-page .tip {
	line-height: 37px;
	text-align: left;
	color: #999;
	font-size: 14px;
}

.login-page .el-form-item {
	margin-bottom: 24px;
}

.login-page .el-form-item:last-child .el-form-item__content {
	line-height: 14px;
	color: #999;
}

.login-page .el-row {
	margin-bottom: 42px;
}

.login-page .login-logo {
	margin-top: 100px;
	margin-bottom: 48px;
	font-size: 20px;
	color: #666;
}

.login-page .login-logo .login-tag {
	cursor: pointer;
}

.login-page .login-logo .login-line {
	margin: 0 20px;
	color: #e6e6e6;
}

.login-page .login-logo .login-logo--on {
	color: rgb(240, 131, 25);
}

.login-page .login-logo .el-icon-close {
	float: right;
	line-height: 30px;
	font-size: 14px;
	cursor: pointer;
}

.login-page .code-wraper,
.login-page .code-wraper .el-form-item__content,
.login-page .el-form-item:after,
.login-page .el-form-item:before,
.login-page .el-form-item__content:after,
.login-page .el-form-item__content:before {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.login-page .code-wraper .el-form-item__content {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 100%;
}

.login-page .code-wraper .sendcode {
	width: 100%;
	height: 44px;
	margin-left: 12px;
	line-height: 44px;
	color: #0095ff;
	background: #fafafa;
	border: 1px solid #f0f0f0;
	cursor: pointer;
}

.login-page .code-wraper .sendcode--disable {
	cursor: not-allowed;
	color: #999;
}

.login-page .captcha-wraper {
	position: relative;
}

.login-page .captcha-img {
	position: absolute;
	top: 0;
	right: 5px;
	display: inline-block;
	background: red;
	height: 34px;
	margin: 5px 0;
	-o-object-fit: cover;
	object-fit: cover;
}

.login-page input {
	height: 36px;
	font-size: 16px;
}

.login-page .el-input__inner {
	height: 44px;
	font-size: 14px;
}

.login-page input::-webkit-input-placeholder {
	font-size: 14px !important;
	color: #999;
}

.login-page input:-moz-input-placeholder,
.login-page input::-moz-input-placeholder {
	font-size: 14px !important;
	color: #999 !important;
}

.login-page input:-ms-input-placeholder {
	font-size: 14px !important;
	color: #999 !important;
}

.login-page .register-tip {
	text-align: right;
	cursor: pointer;
}

.login-page .login-qrcode {
	display: block;
	width: 192px;
	height: 192px;
	margin-left: auto;
	margin-right: auto;
	border: 1px solid #ccc;
}

.login-page .qrcode-tip {
	text-align: center;
	margin-top: 20px;
}

.login-page .btn-full {
	width: 360px;
	height: 44px;
	font-size: 16px;
	font-weight: 600;
}

.login-page .forget-wraper {
	width: 360px;
	margin: 0 auto;
	color: #999;
}

.login-page .forget {
	float: left;
	cursor: pointer;
}

.login-page .register {
	color: rgb(240, 131, 25);
}

.login-page .register:hover {
	color: rgb(240, 161, 25);
}

.login-page .register:active {
	color: #1e88e5;
}

.login-page .register-dialog {
	width: 720px;
	margin: 0 auto;
}

.login-page .register-dialog .register-wraper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-page .register-dialog .register-title {
	color: #333;
	font-size: 16px;
	margin-top: 20px;
	font-weight: 700;
}

.login-page .register-dialog .register-content {
	color: #999;
	font-size: 14px;
	margin-top: 16px;
}

.login-page .register-dialog .register-btn {
	margin-top: 60px;
	width: 80%;
	height: 44px;
	font-size: 14px;
}
</style>
